<script setup>
</script>

<template>
  <div class="relative mt-20">
    <div class="">
      <nuxt-picture loading="lazy" src="/images/datanomic/Frame-917.png"
        :imgAttrs="{ class: 'w-full h-[180px]', alt: 'home-banner' }" class="w-full" />
    </div>
    <div
      class="absolute top-3 left-0 right-0 flex justify-center items-center max-sm:top-5 max-lg:top-3 max-xl:top-0 max-2xl:top-4">
      <p class="text-4xl font-bold max-sm:text-3xl text-[#FCFCFD]">Contact us</p>
    </div>
    <div
      class="absolute top-16 left-0 right-0 flex justify-center items-center max-sm:top-16 max-lg:top-14 max-xl:top-14 max-2xl:top-16">
      <p class="text-base max-sm:text-xs text-[#FCFCFD]">Join our email list to get future update</p>
    </div>
    <div
      class="absolute inset-x-0 bottom-0 flex items-center justify-center p-2 -max-sm:bottom-0 max-xl:top-32 max-lg:top-32 max-2xl:bottom-10 max-2xl:top-32">
      <div class="bg-white border border-gray-300 rounded-[16px] w-full max-w-[675px] max-2xl:max-w-[600px] h-[65px]">
        <div class="flex justify-between items-center">
          <p class="text-base max-sm:text-xs max-sm:pl-2 pl-4 text-[#B1B5C3]">Your Email Address</p>
          <button class="px-10 max-sm:px-8 bg-blue-500 m-1 text-white font-bold text-base rounded-[12px]">Join</button>
        </div>
      </div>
    </div>
  </div>
</template>
